import axios, { AxiosError, AxiosResponse } from 'axios';
import { ROUTES_DEFINITIONS } from 'config/routes/routes';
import ApiClient from 'services/axios-config';
import { logout } from 'store/auth/AuthSlice';
import { AppState, TypedDispatch, store } from 'store/store';

import { getNewAccessToken } from '../handleToken';

interface CustomErrorData {
  message?: string;
}

interface CustomAxiosError extends AxiosError {
  response?: AxiosResponse<CustomErrorData>;
}

export const errorInterceptor = async (error: AxiosError) => {
  if (error.message === 'Network Error') {
    return Promise.reject(new Error('Connection error.'));
  }

  if (error.response?.status === 401) {
    if (/password.*expired/.test((error as CustomAxiosError)?.response?.data?.message || '')) {
      const data = JSON.parse(error.config?.data);
      const { username } = data;
      const routePath = `${ROUTES_DEFINITIONS.passwordExpired.path}?username=${encodeURIComponent(username)}`;
      window.location.replace(routePath);
      return;
    }

    // If the request has the header 'X-Ignored-Interceptors' and the status code is in the list, the request will not be intercepted
    if (error.config?.headers['X-Ignored-Interceptors']) {
      const ignoredInterceptors = error.config?.headers['X-Ignored-Interceptors'].split(',');
      if (ignoredInterceptors?.includes(error.response.status.toString())) {
        return Promise.reject(error);
      }
    }

    const { refreshToken } = ApiClient;

    try {
      const {
        token_type: tokenType, access_token: accessToken, refresh_token: newRefreshToken,
      } = await getNewAccessToken(refreshToken);

      const bearerToken = `${tokenType} ${accessToken}`;
      ApiClient.authenticate(bearerToken, newRefreshToken);

      if (error.config) {
        error.config.headers.Authorization = bearerToken;
        return await axios(error.config);
      }
    } catch {
      await (store.dispatch as TypedDispatch<AppState>)(logout()).unwrap();
      window.location.replace(ROUTES_DEFINITIONS.logout.path);
    }
  }

  return Promise.reject(error);
};
