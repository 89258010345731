/* eslint-disable @typescript-eslint/no-use-before-define */
import FeatureFlags from '@darwin/feature-flags';
import { FeatureFlagOptions } from '@darwin/feature-flags/dist/clients/FeatureFlagTypes';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

export type FeatureFlagsState = {
  fetching: boolean,
  initialized: boolean,
  context: { [key: string]: string },
  flags: { [key: string]: boolean },
  error: string
};

const initialState: FeatureFlagsState = {
  fetching: false,
  initialized: false,
  context: {},
  flags: {},
  error: '',
};

export const initialize = createAsyncThunk(
  'featureFlags/initialize',
  async ({ accountId }: { accountId?: string }, { dispatch, rejectWithValue }) => {
    try {
      const promisedInit = (args: FeatureFlagOptions) => new Promise((resolve) => {
        FeatureFlags.init(args);

        FeatureFlags.on('ready', () => {
          const flags = FeatureFlags.allFlags();
          dispatch(updateFlags(flags));
          resolve(null);
        });
      });
      if (window.env.PUBLIC_LAUNCH_DARKLY_CLIENT_KEY) {
        await promisedInit({
          clientType: 'LAUNCH_DARKLY',
          clientOptions: {
            apiClientKey: window.env.PUBLIC_LAUNCH_DARKLY_CLIENT_KEY,
          },
          context: {
            anonymous: true,
            kind: 'user',
            key: accountId ?? 'LPI',
          },
        });
        FeatureFlags.on(
          'change',
          (flags) => dispatch(updateFlags(flags)),
        );
      }
    } catch (error: unknown) {
      return rejectWithValue((error as AxiosError).message);
    }
  },
);

export const updateContext = createAsyncThunk(
  'featureFlags/updateContext',
  async (context: { [key: string]: string }, { rejectWithValue }) => {
    try {
      FeatureFlags.updateContext(context);
      return context;
    } catch (error: unknown) {
      return rejectWithValue((error as AxiosError).message);
    }
  },
);

const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    updateFlags(state, { payload: flags }: PayloadAction<{ [key: string]: any }>) {
      const expandedFlags = Object.keys(flags).reduce((acc, flag: string) => {
        if (typeof flags[flag] === 'boolean') {
          acc[flag] = Boolean(flags[flag]);
        } else {
          acc[flag] = Boolean(flags[flag].current);
        }
        return acc;
      }, {} as FeatureFlagsState['flags']);

      state.flags = { ...state.flags, ...expandedFlags };
    },
  },
  extraReducers: (builder) => {
    builder
      // initialize
      .addCase(initialize.pending, (state) => {
        state.fetching = true;
        state.initialized = false;
        state.context = {};
        state.flags = {};
        state.error = '';
      })
      .addCase(initialize.fulfilled, (state) => {
        state.fetching = false;
        state.initialized = true;
      })
      .addCase(initialize.rejected, (state, { payload }: PayloadAction<any>) => {
        state.fetching = false;
        state.initialized = false;
        state.error = payload;
      })
      // updateContext
      .addCase(updateContext.pending, (state) => {
        state.fetching = true;
        state.error = '';
      })
      .addCase(updateContext.fulfilled, (state, { payload }: PayloadAction<{ [key: string]: string }>) => {
        state.fetching = false;
        state.context = payload;
      })
      .addCase(updateContext.rejected, (state, { payload }: PayloadAction<any>) => {
        state.fetching = false;
        state.error = payload;
      });
  },
});

export const {
  updateFlags,
} = featureFlagsSlice.actions;

export default featureFlagsSlice.reducer;
