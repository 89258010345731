import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export function CircularLoading() {
  return (
    <Box
      sx={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh',
      }}
    >
      <CircularProgress data-testid="CircularLoading-Progress-test-id" />
    </Box>
  );
}
