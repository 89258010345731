import { blue, grey } from '@mui/material/colors';
import {
  createTheme, ThemeOptions,
} from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface PaletteOptions {
    brand?: PaletteOptions['primary']
  }

  interface Palette {
    brand?: Palette['primary']
  }
}

const palette = {
  primary: {
    main: blue[500],
    dark: blue[800],
    light: blue[200],
  },
  secondary: {
    main: grey[300],
    dark: grey[400],
  },
  tonalOffset: {
    light: 0.7,
    dark: 0.7,
  },
  background: {
    paper: '#ffffff',
    default: '#f7f6f3',
  },
  brand: {
    main: '#041e42',
    light: '#45caea',
    contrastText: '#ffffff',
  },
  lighterBlue: {
    background: '#104389',
  },
  checkbox: {
    backgroundColor: 'rgba(0, 128, 163, 0.15)',
    outlineColor: '#00708F',
    borderColor: '#003764',
  },
};

export const myTheme: ThemeOptions = {
  palette,
  typography: {
    h3: {
      fontWeight: 600,
      fontSize: '32px',
      marginBottom: '16px',
    },
    h4: {
      fontWeight: 600,
      fontSize: '24px',
    },
    fontFamily: 'Open Sans',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: grey[900],
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '3px',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          border: '1px dashed transparent',
          '&.Mui-focusVisible': {
            color: palette.checkbox.outlineColor,
            backgroundColor: palette.checkbox.backgroundColor,
            borderColor: palette.checkbox.borderColor,
          },
        },
      },
    },
  },
};

export const theme = createTheme(myTheme);
