import { Outlet } from 'react-router-dom';

import LpiLayoutTemplate from '../templates/LpiLayoutTemplate/LpiLayoutTemplate';

export default function LpiLayoutPage() {
  return (
    <LpiLayoutTemplate>
      <Outlet />
    </LpiLayoutTemplate>
  );
}
