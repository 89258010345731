import DefaultLayoutTemplate from 'features/layouts/templates/DefaultLayoutTemplate/DefaultLayoutTemplate';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectAuth } from 'store/store';

const DefaultLayoutPage = () => {
  const { isAuthenticated } = useAppSelector(selectAuth);

  return (
    <DefaultLayoutTemplate showLogout={isAuthenticated}>
      <Outlet />
    </DefaultLayoutTemplate>
  );
};

export default DefaultLayoutPage;
