import axios, { AxiosInstance } from 'axios';
import { errorInterceptor } from 'services/interceptors';

class ApiClient {
  static bearerToken = '';

  static refreshToken = '';

  static client: AxiosInstance;

  static init() {
    this.client = axios.create({
      baseURL: window.env.PUBLIC_API_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    this.client.interceptors.response.use(
      (response) => response,
      (error) => errorInterceptor(error),
    );
  }

  static authenticate(token: string, refreshToken: string): void {
    this.bearerToken = token;
    this.refreshToken = refreshToken;
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);

    this.client.defaults.headers.Authorization = token;
  }

  static setTokens(token: string, refreshToken: string): void {
    this.bearerToken = token;
    this.refreshToken = refreshToken;
    this.client.defaults.headers.Authorization = token;
  }

  static remove() {
    this.client.defaults.headers.Authorization = null;
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  }
}

export default ApiClient;
