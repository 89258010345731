import axios from 'axios';

const KEYCLOAK_URL_API = window.env.PUBLIC_KEYCLOAK_URL_API;
const KEYCLOAK_REALM = window.env.PUBLIC_KEYCLOAK_REALM;
const KEYCLOAK_CLIENT_ID = window.env.PUBLIC_KEYCLOAK_CLIENT_ID;
const KEYCLOAK_GRANT_TYPE = window.env.PUBLIC_KEYCLOAK_GRANT_TYPE;

interface GetNewTokenResponse {
  access_token: string;
  expires_in: string;
  refresh_expires_in: string;
  refresh_token: string;
  token_type: string;
  session_state: string;
}

export async function getNewAccessToken(refreshToken: string) {
  const url = `${KEYCLOAK_URL_API}/realms/${KEYCLOAK_REALM}/protocol/openid-connect/token`;
  const params = new URLSearchParams();

  params.append('grant_type', KEYCLOAK_GRANT_TYPE!);
  params.append('client_id', KEYCLOAK_CLIENT_ID!);
  params.append('refresh_token', refreshToken);

  try {
    const { data } = await axios.post<GetNewTokenResponse>(
      url,
      params.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

    return data;
  } catch (err: any) {
    throw new Error('Unable to refresh access token.');
  }
}
