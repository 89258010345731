/* eslint-disable max-len */
import { enc } from 'crypto-js';

export const isValidEmailExpression = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;

export const isThereHtmlTags = /^[^</>]*$/;

export const hasLowerChar = /[a-z]+/;
export const hasUpperChar = /[A-Z]+/;
export const hasNumberChar = /[0-9]+/;
export const hasSpecialChar = /[~!@#$%^&*()\-_+{}|[\]\\:;"'?,./]+/;
export const allowedChars = /^[0-9A-Za-z~!@#$%^&*()\-_+{}|[\]\\:;"'?,./]+$/;
export const passwordLength = /^.{8,30}$/;
export const email = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;

export const getFormattedToken = (token: string) => {
  let formattedToken = '';

  if (token?.startsWith('Bearer ')) {
    formattedToken = token.substring('Bearer '.length);
  }
  return formattedToken;
};

export const strongPassword = (password: any) => {
  if (!passwordLength.test(password)) return false;
  if (!allowedChars.test(password)) return false;

  const checks = [
    hasLowerChar.test(password),
    hasUpperChar.test(password),
    hasNumberChar.test(password),
    hasSpecialChar.test(password),
  ];

  if (checks.filter(Boolean).length < 3) return false;
  return true;
};

export const getEncodedUserId = (jwt: string) => {
  const base64Url = jwt.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = enc.Base64.parse(base64).toString(enc.Utf8);
  const jsonParsed = JSON.parse(jsonPayload);
  return jsonParsed.user_id;
};

export const formatAssessmentDate = (assessmentDate: any) => {
  const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
  const receivedDate = new Date(assessmentDate);

  return new Intl.DateTimeFormat(userLocale).format(receivedDate);
};

export const trimAndLowCase = (text: string) => (text.trim().toLowerCase());

export const downloadReport = async (report: string) => {
  try {
    if (report) {
      const response = await fetch(report);
      const blob = await response.blob();

      const fileUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = fileUrl;

      const originalFileName = `${report.split('.pdf')[0]}.pdf`;
      a.download = originalFileName;
      a.click();
    }
  } catch (err) {
    console.error(err);
  }
};

export const printReport = (report: string) => {
  try {
    window.open(report, '_blank');
  } catch (err) {
    console.error(err);
  }
};

export const getProductsNames = (productMap: { [key: string]: { name: string } }): { id: string; productName: string; }[] => {
  const products: { id: string; productName: string; }[] = [];
  Object.keys(productMap).forEach((productId: string) => {
    products.push({ id: productId, productName: productMap[productId].name });
  });
  return products;
};
