import * as falso from '@ngneat/falso';

falso.seed('leader-mock');
const idFactory = falso.incrementalNumber();

export const createMockLeader = (): Leader => ({
  userId: idFactory().toString(),
  firstName: falso.randFirstName(),
  lastName: falso.randLastName(),
  email: falso.randEmail(),
});

export const createMockLeaders = (length?: number): Leader[] => (
  Array.from({ length: length || falso.randNumber({ min: 0, max: 100 }) }, () => createMockLeader())
);
