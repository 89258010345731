import { Suspense, useEffect, useState } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { CircularLoading } from 'components/CircularLoading/CircularLoading';
import { createHandlers } from 'mocks/handlers';
import { Outlet } from 'react-router-dom';
import ApiClient from 'services/axios-config';
import { fetchConsents } from 'store/consents/ConsentsSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAuth } from 'store/store';
import { fetchUser } from 'store/user/UserSlice';
import pendoClient from 'utils/pendo';

export default function App() {
  const dispatch = useAppDispatch();
  const {
    isAuthenticated, userId,
    accessToken, refreshToken,
  } = useAppSelector(selectAuth);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    setInitialized(false);
    // eslint-disable-next-line global-require
    const { worker } = require('./mocks/browser');
    worker.use(...(createHandlers(window.env.PUBLIC_API_BASE_URL)));
    worker.start();

    if (isAuthenticated && userId) {
      ApiClient.setTokens(accessToken, refreshToken);
      const promises: Promise<any>[] = [];

      promises.push(dispatch(fetchConsents(userId)));
      promises.push(dispatch(fetchUser(userId)));

      Promise.all(promises)
        .finally(() => {
          setInitialized(true);
        });
    }

    pendoClient.initialize();

    setInitialized(true);
  }, [isAuthenticated, window.env.PUBLIC_API_BASE_URL]);

  return (
    <>
      <CssBaseline />

      <Suspense fallback={<CircularLoading />}>
        {initialized && <Outlet />}
      </Suspense>
    </>
  );
}
