import {
  Box,
  Divider,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import styles from './Footer.module.scss';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ bgcolor: 'brand.main', color: 'brand.contrastText' }} p={4}>
      <Stack
        spacing={4}
        divider={<Divider variant="middle" flexItem sx={{ borderColor: 'brand.light' }} />}
        alignItems="center"
      >
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          className="focusVisible--darkBackgroundLightOutline"
          spacing={3}
        >
          <Stack
            spacing={0.5}
          >
            <Typography variant="body2">
              {t('layout.footer.copyrightLpi')}
            </Typography>

            <Typography variant="body2">
              {t('layout.footer.copyrightWiley')}
            </Typography>

            <Typography variant="body2">
              {t('layout.footer.rightsReserved')}
            </Typography>
          </Stack>

          <Stack
            sx={{
              flexDirection: { sm: 'column', md: 'row' },
              gap: { sm: 1, md: 2 },
            }}
          >
            <Link href="https://www.wiley.com/en-us/terms-of-use" target="_blank" color="brand.contrastText">
              {t('layout.footer.terms')}
            </Link>

            <Link href="https://www.wiley.com/privacy" target="_blank" color="brand.contrastText">
              {t('layout.footer.privacyPolicy')}
            </Link>
          </Stack>
        </Stack>

        <img src="/assets/images/Wiley_Wordmark_white.svg" alt="Wiley logo" className={styles['Footer-logo']} />
      </Stack>
    </Box>
  );
};

export default Footer;
