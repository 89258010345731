import React, { Suspense } from 'react';

import Router from 'config/routes/Router';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import ApiClient from 'services/axios-config';
import { store, persistor } from 'store/store';

import './i18n';
import './fonts';

ApiClient.init();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <Provider store={store}>
    <Suspense>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={Router} />
      </PersistGate>
    </Suspense>
  </Provider>,
);
