import { QuestionListLayoutProps } from '@darwin/assessment-ui/build/types/PageShellLayout';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { LpiService } from 'services/lpi/lpi';
import { Assessment } from 'types/Assessment';
import { ApiStatus } from 'types/enums/ApiStatus';

export type AssessmentsState = {
  status: ApiStatus;
  assessmentList: Assessment[];
  assessmentQuestions?: QuestionListLayoutProps['questionList'];
  currentPage?: QuestionsType;
  links?: AssessmentQuestionsLink;
  progress: number;
  hasPreviousPage: boolean;
  isLastPage: boolean;
  errorCode?: number;
};

const initialState: AssessmentsState = {
  status: ApiStatus.IDLE,
  assessmentList: [],
  progress: 0,
  hasPreviousPage: false,
  isLastPage: false,
};

export const listAssessements = createAsyncThunk(
  'assessments/list',
  async (_, { rejectWithValue }) => {
    try {
      const response = await LpiService.Assessments.getAssessmentList();

      return response;
    } catch (error: unknown) {
      return rejectWithValue((error as AxiosError).response?.status);
    }
  },
);

export const getQuestions = createAsyncThunk(
  'assessment/getQuestions',
  async (url: string, { rejectWithValue }) => {
    try {
      const response = await LpiService.Assessments.getQuestions(url);

      return response;
    } catch (error: unknown) {
      return rejectWithValue((error as AxiosError).response?.status);
    }
  },
);

export const saveAssessment = createAsyncThunk(
  'assessment/save',
  async (request: { id: string, questions: AssessmentResponse[] }, { rejectWithValue }) => {
    try {
      await LpiService.Assessments.saveAndContinue(request.id, request.questions);
    } catch (error: unknown) {
      return rejectWithValue((error as AxiosError).response?.status);
    }
  },
);

const assessmentsSlice = createSlice({
  name: 'learnerAssessments',
  initialState,
  reducers: {
    clearAssessmentState: (state) => {
      state.progress = 0;
      state.assessmentQuestions = [];
      state.currentPage = undefined;
      state.links = undefined;
      state.progress = 0;
      state.hasPreviousPage = false;
      state.isLastPage = false;
    },
  },
  extraReducers: (builder) => {
    builder
    // listAssessment
      .addCase(listAssessements.pending, (state) => {
        state.status = ApiStatus.LOADING;
      })
      .addCase(listAssessements.fulfilled, (
        state,
        { payload }: PayloadAction<Assessment[]>,
      ) => {
        state.status = ApiStatus.SUCCEEDED;
        state.assessmentList = payload;
      })
      .addCase(listAssessements.rejected, (state, { payload }: PayloadAction<unknown>) => {
        state.status = ApiStatus.FAILED;
        state.errorCode = Number(payload);
      })
    // getQuestion
      .addCase(getQuestions.pending, (state) => {
        state.status = ApiStatus.LOADING;
      })
      .addCase(getQuestions.fulfilled, (state, {
        payload: {
          type, questions, links, page, totalPages,
        },
      }: PayloadAction<GetAssessmentQuestionsResponse>) => {
        state.status = ApiStatus.SUCCEEDED;
        state.assessmentQuestions = questions.map((currentQuestion) => ({
          ...currentQuestion,
          id: currentQuestion.id.toString(),
          type: 'dropdown',
          label: '', // Set label as empty due to render issues on dropdownQuestion
          question: currentQuestion.text,
          order: currentQuestion.label,
          answer: currentQuestion.response?.value || 0,
          options: currentQuestion.answers,
          onValueChange: () => null,
        }));
        state.currentPage = type;
        state.links = links;
        state.hasPreviousPage = !!links.previous;
        state.isLastPage = (page === totalPages);

        if (type === 'Assessment') {
          // Set progress for Assessment pages, accounting for completed Demographics as a part of overall progress.
          const progressPercentage = page === 1 ? 25 : Math.round((page / (totalPages + 1)) * 100);
          state.progress = progressPercentage;
        }
      })
      .addCase(getQuestions.rejected, (state, { payload }: PayloadAction<unknown>) => {
        state.status = ApiStatus.FAILED;
        state.errorCode = Number(payload);
      })
    // saveAndContinue
      .addCase(saveAssessment.pending, (state) => {
        state.status = ApiStatus.LOADING;
      })
      .addCase(saveAssessment.fulfilled, (state) => {
        state.status = ApiStatus.SUCCEEDED;
      })
      .addCase(saveAssessment.rejected, (state, { payload }: PayloadAction<unknown>) => {
        state.status = ApiStatus.FAILED;
        state.errorCode = Number(payload);
      });
  },
});

export const {
  clearAssessmentState,
} = assessmentsSlice.actions;

export default assessmentsSlice.reducer;
