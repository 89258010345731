/* eslint-disable @typescript-eslint/no-redeclare */
import { lazy } from 'react';

import App from 'App';
import DefaultLayoutPage from 'features/layouts/pages/DefaultLayoutPage';
import LpiLayoutPage from 'features/layouts/pages/LpiLayoutPage';
import { RouteObject } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import { ROUTES, ROUTES_DEFINITIONS, reverseRoute } from './routeDefinitions';

const AuthPage = lazy(() => import('features/authentication/pages/AuthPage'));
const AuthFromLegacy = lazy(() => import('features/authentication/pages/AuthFromLegacy'));
const Register = lazy(() => import('features/authentication/pages/RegisterPage'));
const LogoutPage = lazy(() => import('features/authentication/pages/LogoutPage'));
const ForgotPassword = lazy(() => import('features/authentication/pages/ForgotPasswordPage'));
const ResetPassword = lazy(() => import('features/authentication/pages/ResetPasswordPage'));
const PasswordExpired = lazy(() => import('features/authentication/pages/PasswordExpiredPage'));
const AcceptTerms = lazy(() => import('features/authentication/pages/AcceptTermsPage'));
const Home = lazy(() => import('features/home/pages/HomePage'));
const Demographics = lazy(() => import('features/assessment/pages/DemographicsPage'));
const Instructions = lazy(() => import('features/assessment/pages/InstructionsPage'));
const Assessment = lazy(() => import('features/assessment/pages/AssessmentPage'));
const AssessmentResults = lazy(() => import('features/assessment/pages/AssessmentResultsPage'));
const NotFound = lazy(() => import('features/errors/pages/NotFoundPage'));
const Error = lazy(() => import('features/errors/pages/ErrorPage'));
const ViewAssessments = lazy(() => import('features/admin/pages/ViewAssessmentsPage'));
const Reports = lazy(() => import('features/admin/pages/ReportsPage'));
const ReportCustomization = lazy(() => import('features/admin/pages/ReportCustomizationPage'));
const Invitation = lazy(() => import('features/leader/pages/InvitationPage'));

export { ROUTES_DEFINITIONS, reverseRoute };

export const ROUTING: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        element: <DefaultLayoutPage />,
        children: [
          {
            element: <ProtectedRoute />,
            children: [
              {
                element: <Home />,
                path: ROUTES_DEFINITIONS[ROUTES.home].path,
              },
              {
                element: <Demographics />,
                path: ROUTES_DEFINITIONS[ROUTES.demographics].path,
              },
              {
                element: <Instructions />,
                path: ROUTES_DEFINITIONS[ROUTES.instructions].path,
              },
              {
                element: <Assessment />,
                path: ROUTES_DEFINITIONS[ROUTES.assessment].path,
              },
              {
                element: <AssessmentResults />,
                path: ROUTES_DEFINITIONS[ROUTES.assessmentResults].path,
              },
              {
                element: <AcceptTerms />,
                path: ROUTES_DEFINITIONS[ROUTES.acceptTerms].path,
              },
              {
                element: <LogoutPage />,
                path: ROUTES_DEFINITIONS[ROUTES.logout].path,
              },
              {
                element: <Invitation />,
                path: ROUTES_DEFINITIONS[ROUTES.invitation].path,
              },
              {
                element: <h1>Your Assessments</h1>,
                path: ROUTES_DEFINITIONS[ROUTES.yourAssessments].path,
              },
            ],
          },
          {
            element: <PublicRoute restricted />,
            children: [
              {
                element: <AuthPage />,
                path: ROUTES_DEFINITIONS[ROUTES.auth].path,
              },
              {
                element: <Register />,
                path: ROUTES_DEFINITIONS[ROUTES.register].path,
              },
              {
                element: <ForgotPassword />,
                path: ROUTES_DEFINITIONS[ROUTES.forgotPassword].path,
              },
              {
                element: <ResetPassword />,
                path: ROUTES_DEFINITIONS[ROUTES.resetPassword].path,
              },
              {
                element: <PasswordExpired />,
                path: ROUTES_DEFINITIONS[ROUTES.passwordExpired].path,
              },
            ],
          },
          {
            element: <PublicRoute />,
            children: [
              {
                element: <Error />,
                path: ROUTES_DEFINITIONS[ROUTES.error].path,
              },
              {
                element: <NotFound />,
                path: ROUTES_DEFINITIONS[ROUTES.catch].path,
              },
              {
                element: <AuthFromLegacy />,
                path: ROUTES_DEFINITIONS[ROUTES.authFromLegacy].path,
              },
            ],
          },
        ],
      },
      {
        element: <LpiLayoutPage />,
        children: [
          {
            element: <ProtectedRoute />,
            children: [
              {
                element: <ViewAssessments />,
                path: ROUTES_DEFINITIONS[ROUTES.viewAssessments].path,
              },
              {
                element: <Reports />,
                path: ROUTES_DEFINITIONS[ROUTES.reports].path,
              },
              {
                element: <ReportCustomization />,
                path: ROUTES_DEFINITIONS[ROUTES.reportCustomization].path,
              },
            ],
          },
        ],
      },
    ],
  },
];
