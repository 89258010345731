import { CSSProperties } from 'react';

import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import {
  ThemeOptions, SimplePaletteColorOptions, createTheme,
} from '@mui/material/styles';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';

interface CustomPalette {
  primary: {
    light: string,
    background: {
      light: string,
      dark: string,
    },
  },
  secondary: {
    main: string,
  },
  accent: {
    accent1: string,
    accent2: string,
    accent3: string,
    accent4: string,
    accent5: string,
  },
  error: {
    main: string
    light: string,
    dark: string,
  },
  warning?: {
    main: string
    light: string,
    dark: string,
  },
  success?: {
    main: string
    light: string,
    dark: string,
  },
}

declare module '@mui/material/styles' {
  interface DefaultPaletteOptions extends PaletteOptions {
    custom?: CustomPalette,
    primary?: SimplePaletteColorOptions,
    secondary?: SimplePaletteColorOptions,
  }

  interface TypographyVariants {
    label?: CSSProperties;
  }

  interface TypographyStyleOptions {
    label?: CSSProperties;
  }

  interface Palette {
    custom?: CustomPalette,
  }

  interface DefaultThemeOptions extends ThemeOptions {
    palette: DefaultPaletteOptions
  }
}

export const primaryColors = {
  midnigth: '#041E42',
  sky: '#05C3DD',
  ocean: '#0080A3 ',
  blueberry: '#0A52A6',
  darkBlueberry: '#0A3970',
  electricBerry: '#0438BC',
  tangerine: '#FC4C02',
};

const errorColors = {
  main: '#B00718',
  outlinedHover: '#FDEDEE',
};

export const grays = {
  black: '#000000',
  gray900: '#262626',
  gray800: '#333333',
  gray700: '#474747',
  gray600: '#616161',
  gray500: '#707070',
  gray400: '#949494',
  gray300: '#B5B5B5',
  gray200: '#CECECE',
  gray110: '#E6E6E6',
  gray100: '#F8F8F8',
  white: '#FFFFFF',
};

export const productColors = {
  lpi360: primaryColors.midnigth,
  lpi360Plus: primaryColors.midnigth,
  lpiSelf: primaryColors.midnigth,
  selfEmpowered: primaryColors.midnigth,
  preWork: primaryColors.midnigth,
  student360: primaryColors.tangerine,
  studentSelf: primaryColors.tangerine,
};

export const blues = {
  ocean: primaryColors.ocean,
  blueberry: primaryColors.blueberry,
};

const breakpoints = createBreakpoints({});

export const defaultCustomPalette: CustomPalette = {
  primary: {
    background: {
      light: '#FFFFFF',
      dark: primaryColors.midnigth,
    },
    light: primaryColors.sky,
  },
  secondary: {
    main: grays.white,
  },
  accent: {
    accent1: primaryColors.tangerine,
    accent2: '#DD4201',
    accent3: '#00708F',
    accent4: '#1154B9',
    accent5: '#0093D0',
  },
  error: {
    main: '#B52121',
    light: '#FFEAEA',
    dark: '#990000',
  },
};

export const theme = createTheme({
  palette: {
    primary: {
      light: defaultCustomPalette.primary.light,
      main: grays.gray800,
      dark: defaultCustomPalette.primary.background.dark,
    },
    secondary: {
      main: grays.white,
    },
    error: {
      main: defaultCustomPalette.error.main,
      light: defaultCustomPalette.error.light,
      dark: defaultCustomPalette.error.dark,
    },
    contrastThreshold: 4.5,
  },
  typography: {
    fontFamily: [
      'Open Sans',
      'sans-serif',
    ].join(','),
    fontSize: 20,
    fontWeightRegular: 300,
    fontWeightMedium: 400,
    allVariants: {
      color: grays.gray800,
    },
    h1: {
      fontSize: '2.5rem',
      fontWeight: 300,
      lineHeight: 'normal',
      [breakpoints.down('md')]: {
        fontSize: '1.875rem',
        lineHeight: '3rem',
      },
    },
    h2: {
      fontSize: '1.875rem',
      fontWeight: 'normal',
      lineHeight: '3rem',
      [breakpoints.down('md')]: {
        fontSize: '1.375rem',
        lineHeight: '2.25rem',
      },
    },
    h3: {
      fontSize: '1.375rem',
      fontWeight: 'normal',
      lineHeight: '2.25rem',
      [breakpoints.down('md')]: {
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
      },
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 'normal',
      lineHeight: '2.25rem',
      [breakpoints.down('md')]: {
        fontSize: '1rem',
        lineHeight: '1.625rem',
      },
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 'normal',
      lineHeight: '1.625rem',
      [breakpoints.down('md')]: {
        fontSize: '0.875rem',
        lineHeight: '1.5rem',
      },
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 'normal',
      lineHeight: '1.5rem',
      [breakpoints.down('md')]: {
        fontSize: '0.75rem',
        lineHeight: '1.25rem',
      },
    },
    caption: {
      fontSize: '0.688rem',
      fontWeight: 'normal',
      lineHeight: '1rem',
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: 'normal',
      lineHeight: '1.5rem',
    },
    body2: {
      fontSize: '0.75rem',
      fontWeight: 'normal',
      lineHeight: '1.25rem',
    },
    overline: {
      fontSize: '0.6875rem',
      lineHeight: '1rem',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      fontWeight: 600,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '11px',
          fontWeight: 600,
          lineHeight: '1.25rem', // 20px
          letterSpacing: '1px',
        },
        contained: {
          backgroundColor: primaryColors.blueberry,
          color: grays.white,
          '&:hover,&:focus': {
            backgroundColor: primaryColors.darkBlueberry,
          },
          '&.Mui-disabled': {
            backgroundColor: `${grays.black}1F`,
            color: grays.gray500,
          },
        },
        containedSizeSmall: {
          padding: '5px 0.625rem', // 5px 10px
        },
        containedSizeMedium: {
          padding: '0.5rem 0.75rem', // 8px 12px
        },
        containedSizeLarge: {
          padding: '0.5rem 1.375rem', // 8px 22px
        },
        outlined: {
          borderColor: primaryColors.blueberry,
          color: primaryColors.blueberry,
          '&:hover,&:focus': {
            borderColor: primaryColors.darkBlueberry,
            color: primaryColors.darkBlueberry,
          },
          '&.Mui-disabled': {
            borderColor: grays.gray300,
            color: grays.gray500,
          },
        },
        outlinedSizeSmall: {
          padding: '5px 0.625rem', // 5px 10px
        },
        outlinedSizeMedium: {
          padding: '0.5rem 0.75rem', // 8px 12px
        },
        outlinedSizeLarge: {
          padding: '0.5rem 1.375rem', // 8px 22px
        },
        text: {
          textDecoration: 'none',
          color: primaryColors.blueberry,
          '&:hover,&:focus': {
            backgroundColor: `${primaryColors.blueberry}14`,
          },
          '&.Mui-disabled': {
            color: grays.gray500,
          },
        },
        textSizeSmall: {
          padding: '5px 0.625rem', // 5px 10px
        },
        textSizeMedium: {
          padding: '0.5rem', // 8px
        },
        textSizeLarge: {
          padding: '0.5rem 11px', // 8px 11px
        },
        textError: {
          color: errorColors.main,
          '&:hover,&:focus': {
            backgroundColor: errorColors.outlinedHover,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: defaultCustomPalette.accent.accent3,
          '& :first-of-type': {
            fontSize: '24px',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: grays.gray800,
          '&:hover,&:focus': {
            backgroundColor: `${grays.gray700}14`,
          },
          '&.Mui-checked': {
            color: primaryColors.blueberry,
            '&:hover,&:focus': {
              backgroundColor: `${grays.gray700}14`,
            },
          },
          '&.Mui-disabled': {
            color: grays.gray400,
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: false,
      },
      styleOverrides: {
        root: {
          color: grays.gray500,
          '&:hover': {
            backgroundColor: `${grays.gray700}14`,
          },
          '&.Mui-checked': {
            color: primaryColors.blueberry,
            '&:hover,&:focus': {
              backgroundColor: `${defaultCustomPalette.accent.accent3}14`,
            },
          },
          '&.Mui-disabled': {
            color: grays.gray400,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          '&.Mui-disabled': {
            color: grays.gray700,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: defaultCustomPalette.accent.accent4,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: `${defaultCustomPalette.primary.background.dark}1a`,
          height: '8px',
        },
        bar: {
          backgroundColor: defaultCustomPalette.primary.background.dark,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: grays.gray800,
          '&:not(.Mui-disabled):hover': {
            backgroundColor: grays.gray100,
            [`.${outlinedInputClasses.notchedOutline}`]: {
              borderColor: grays.gray700,
            },
            [`&.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: defaultCustomPalette.error.main,
            },
          },
          [`&.Mui-focused:not(.Mui-error) .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: defaultCustomPalette.accent.accent3,
          },
          '&.Mui-disabled': {
            [`.${outlinedInputClasses.notchedOutline}`]: {
              borderColor: grays.gray400,
            },
            '& .MuiInputAdornment-root': {
              color: grays.gray400,
            },
          },
          [`&.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
            borderWidth: '2px',
          },
          '& input[type=password]::-ms-reveal, & input[type=password]::-ms-clear': {
            display: 'none',
          },
        },
        notchedOutline: {
          borderColor: grays.gray700,
        },
        input: {
          '&::placeholder': {
            color: grays.gray700,
            opacity: 1,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          whiteSpace: 'normal',
          textOverflow: 'clip',
          '&.Mui-disabled': {
            color: grays.gray700,
          },
        },
        shrink: {
          color: grays.gray700,
          '&.Mui-focused:not(.Mui-error)': {
            color: defaultCustomPalette.accent.accent3,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          '&.Mui-disabled': {
            color: grays.gray700,
            '-webkit-text-fill-color': grays.gray700,
          },
        },
        icon: {
          color: grays.gray800,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: grays.gray700,
        },
        colorPrimary: {
          '&.Mui-checked': {
            color: defaultCustomPalette.accent.accent5,
          },
        },
        track: {
          backgroundColor: grays.gray700,
          '.Mui-checked.Mui-checked + &': {
            backgroundColor: defaultCustomPalette.accent.accent5,
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: grays.gray800,
          '&.Mui-disabled': {
            color: grays.gray400,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: grays.gray700,
          marginTop: '5px',
          '&.Mui-disabled': {
            color: grays.gray700,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: `${grays.gray700}bf`,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        outlined: {
          borderColor: grays.gray300,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          boxShadow: 'none',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: grays.gray110,
          '& .MuiCardHeader-root': {
            paddingTop: 0,
            paddingLeft: 0,
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        subheader: {
          fontSize: '0.875rem',
          color: grays.gray800,
        },
        avatar: {
          '& + .MuiCardHeader-content': {
            '& .MuiCardHeader-title': {
              fontSize: '1rem',
            },
            '& .MuiCardHeader-subheader': {
              fontSize: '0.75rem',
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '1.5rem',
          [breakpoints.only('xs')]: {
            paddingLeft: '1rem',
            paddingRight: '1rem',
          },
        },
        root: {
          '& .MuiDialogTitle-root': {
            padding: 0,
            fontSize: '1.376rem',
          },
          '& .MuiDialogContent-root': {
            padding: 0,
            paddingBottom: '1.5rem', // 24px
          },
          '& .MuiDialogActions-root': {
            columnGap: '0.75rem',
            padding: 0,
            '& > :not(:first-of-type)': {
              marginLeft: '0',
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
        head: {
          fontWeight: 600,
        },
      },
    },
  },
});
