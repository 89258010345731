import { ReactNode } from 'react';

import { ThemeProvider } from '@mui/material';
import { theme } from 'config/defaultTheme';

export interface LpiLayoutTemplateProps {
  children: ReactNode;
}

export default function LpiLayoutTemplate({ children }: LpiLayoutTemplateProps) {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
}
