import {
  Box,
  Container,
  Stack,
  ThemeProvider,
} from '@mui/material';
import { theme } from 'config/studentTheme';
import Footer from 'features/layouts/components/Footer/Footer';
import Header from 'features/layouts/components/Header/Header';
import NavBar from 'features/layouts/components/NavBar/NavBar';

export interface DefaultLayoutTemplateProps {
  showLogout: boolean,
  children?: JSX.Element[] | JSX.Element,
}

const DefaultLayoutTemplate = ({ showLogout, children }: DefaultLayoutTemplateProps) => (
  <ThemeProvider theme={theme}>
    <Container maxWidth="lg">
      <Stack
        minHeight="100vh"
      >
        <Header />

        <NavBar showLogout={showLogout} />

        <Box
          py={4}
          flex="1"
        >
          {children}
        </Box>

        <Footer />
      </Stack>
    </Container>
  </ThemeProvider>
);

export default DefaultLayoutTemplate;
