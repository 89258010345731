import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectAuth } from 'store/store';

import { ROUTES_DEFINITIONS } from './routes';

interface PublicRouteProps {
  restricted?: boolean,
}

export default function PublicRoute({ restricted }: PublicRouteProps) {
  const { isAuthenticated } = useAppSelector(selectAuth);
  const location = useLocation();

  if (isAuthenticated && restricted) {
    const redirectTo = location.state?.from || ROUTES_DEFINITIONS.home.path;

    return <Navigate to={redirectTo} replace />;
  }

  return <Outlet />;
}
