import {
  ThunkAction, Action, configureStore,
} from '@reduxjs/toolkit';
import { UnknownAction, combineReducers } from 'redux';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ThunkDispatch } from 'redux-thunk';

import AssessmentsReducer from './assessments/AssessmentsSlice';
import AuthReducer from './auth/AuthSlice';
import ConsentsReducer from './consents/ConsentsSlice';
import FeatureFlagsReducer from './featureFlags/FeatureFlagsSlice';
import UserReducer from './user/UserSlice';

const rootReducer = combineReducers({
  auth: AuthReducer,
  consents: ConsentsReducer,
  featureFlags: FeatureFlagsReducer,
  user: UserReducer,
  assessments: AssessmentsReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export const persistor = persistStore(store);

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type TypedDispatch<T> = ThunkDispatch<T, any, UnknownAction>;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;

export const selectAuth = (state: RootState) => state.auth;
export const selectConsents = (state: RootState) => state.consents;
export const selectFeatureFlags = (state: RootState) => state.featureFlags;
export const selectUser = (state: RootState) => state.user;
export const selectAssessments = (state: RootState) => state.assessments;
