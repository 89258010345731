import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { getEncodedUserId } from 'helpers/helpers';
import ApiClient from 'services/axios-config';
import { LpiService } from 'services/lpi/lpi';
import AuthFromLegacyRequest from 'services/lpi/types/Auth/AuthFromLegacyRequest';
import AuthRequest from 'services/lpi/types/Auth/AuthRequest';
import AuthResponse from 'services/lpi/types/Auth/AuthResponse';
import RegisterRequest from 'services/lpi/types/Auth/RegisterRequest';
import ResetPasswordRequest from 'services/lpi/types/Auth/ResetPasswordRequest';
import { acceptConsent, fetchConsents } from 'store/consents/ConsentsSlice';
import { ApiStatus } from 'types/enums/ApiStatus';
import { ConsentType } from 'types/enums/ConsentType';

const PLATFORM_NAME = window.env.PUBLIC_PLATFORM_NAME;
const PLATFORM_ROLE = window.env.PUBLIC_PLATFORM_ROLE;
const PLATFORM_CUSTOMER = window.env.PUBLIC_PLATFORM_CUSTOMER;
const PLATFORM_ID = window.env.PUBLIC_PLATFORM_ID;

export type AuthState = {
  isAuthenticated: boolean,
  status: ApiStatus,
  accessToken: string,
  refreshToken: string,
  userId: number,
};

const initialState: AuthState = {
  status: ApiStatus.IDLE,
  isAuthenticated: false,
  accessToken: '',
  refreshToken: '',
  userId: 0,
};

export const login = createAsyncThunk(
  'auth/login',
  async (request: AuthRequest, { rejectWithValue }) => {
    try {
      const response = await LpiService.Auth.Auth(request);

      return response;
    } catch (error: unknown) {
      return rejectWithValue((error as AxiosError).response?.status);
    }
  },
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      await LpiService.Auth.Logout();

      return true;
    } catch (error: unknown) {
      return rejectWithValue((error as AxiosError).response?.status);
    }
  },
);

export const register = createAsyncThunk(
  'auth/register',
  async (request: RegisterRequest, { dispatch, rejectWithValue }) => {
    try {
      const {
        email, orgOrSchool, password, ...restRegisterRequest
      } = request;

      const userMappings = {
        userMappings: [{
          platformName: PLATFORM_NAME,
          platformCustomer: PLATFORM_CUSTOMER,
          platformRole: PLATFORM_ROLE,
          platformUserId: PLATFORM_ID,
          platformAccountId: '',
          platformData: orgOrSchool || '',
        }],
      };

      const formDataWithUserMappings = {
        username: email,
        email,
        orgOrSchool,
        password,
        ...restRegisterRequest,
        ...userMappings,
      };

      const { userId, country } = await LpiService.Auth.Register(formDataWithUserMappings);

      await dispatch(login({ username: email, password })).unwrap();

      await dispatch(acceptConsent({ userId: userId!, policyType: ConsentType.PRIVACY_POLICY }));

      if (country === 'China') {
        await dispatch(acceptConsent({ userId: userId!, policyType: ConsentType.PIPL }));
      }

      await dispatch(fetchConsents(userId!));
    } catch (error: unknown) {
      return rejectWithValue((error as AxiosError).response?.status);
    }
  },
);

export const forgotPassword = createAsyncThunk(
  'user/forgotPassword',
  async (email: string, { rejectWithValue }) => {
    try {
      await LpiService.Auth.ForgotPassword(email);
    } catch (error: unknown) {
      return rejectWithValue((error as AxiosError).response?.status);
    }
  },
);

export const verifyResetCode = createAsyncThunk(
  'user/verifyResetCode',
  async (code: string, { rejectWithValue }) => {
    try {
      if (code === '') {
        return rejectWithValue(400);
      }

      await LpiService.Auth.VerifyResetCode(code);
    } catch (error: unknown) {
      return rejectWithValue((error as AxiosError).response?.status);
    }
  },
);

export const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async (request: ResetPasswordRequest, { rejectWithValue }) => {
    try {
      await LpiService.Auth.ResetPassword(request);
    } catch (error: unknown) {
      return rejectWithValue((error as AxiosError).response?.status);
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticate: (state) => {
      state.isAuthenticated = true;
    },
    setAuthenticated: (state, { payload }: PayloadAction<AuthState['isAuthenticated']>) => {
      state.isAuthenticated = payload;
    },
    authenticateFromLegacy: (state, { payload }: PayloadAction<AuthFromLegacyRequest>) => {
      state.status = ApiStatus.SUCCEEDED;
      state.isAuthenticated = true;
      state.accessToken = `${payload.tokenType} ${payload.accessToken}`;
      state.refreshToken = payload.refreshToken;
      state.userId = getEncodedUserId(payload.accessToken);
      ApiClient.setTokens(state.accessToken, payload.refreshToken);
    },
  },
  extraReducers: (builder) => {
    builder
      // login
      .addCase(login.pending, (state) => {
        state.status = ApiStatus.LOADING;
      })
      .addCase(login.fulfilled, (
        state,
        { payload: { token_type, refresh_token, access_token } }: PayloadAction<AuthResponse>,
      ) => {
        state.status = ApiStatus.SUCCEEDED;
        state.isAuthenticated = true;
        state.accessToken = `${token_type} ${access_token}`;
        state.refreshToken = refresh_token;
        state.userId = getEncodedUserId(access_token);
        ApiClient.setTokens(state.accessToken, refresh_token);
      })
      .addCase(login.rejected, (state) => {
        state.status = ApiStatus.FAILED;
        state.isAuthenticated = false;
        state.accessToken = '';
        state.refreshToken = '';
      })
      // logout
      .addCase(logout.pending, (state) => {
        state.status = ApiStatus.LOADING;
      })
      .addCase(logout.fulfilled, (state) => {
        state.status = ApiStatus.SUCCEEDED;
        state.isAuthenticated = false;
        state.accessToken = '';
        state.refreshToken = '';
        state.userId = 0;
        ApiClient.remove();
      })
      .addCase(logout.rejected, (state) => {
        state.isAuthenticated = false;
        state.status = ApiStatus.FAILED;
      })
      // register
      .addCase(register.pending, (state) => {
        state.status = ApiStatus.LOADING;
      })
      .addCase(register.fulfilled, (state) => {
        state.status = ApiStatus.SUCCEEDED;
      })
      .addCase(register.rejected, (state) => {
        state.status = ApiStatus.FAILED;
      })
      // forgot password
      .addCase(forgotPassword.pending, (state) => {
        state.status = ApiStatus.LOADING;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.status = ApiStatus.SUCCEEDED;
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.status = ApiStatus.FAILED;
      })
      // verifyResetCode
      .addCase(verifyResetCode.pending, (state) => {
        state.status = ApiStatus.LOADING;
      })
      .addCase(verifyResetCode.fulfilled, (state) => {
        state.status = ApiStatus.SUCCEEDED;
      })
      .addCase(verifyResetCode.rejected, (state) => {
        state.status = ApiStatus.FAILED;
      })
      // resetPassword
      .addCase(resetPassword.pending, (state) => {
        state.status = ApiStatus.LOADING;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.status = ApiStatus.SUCCEEDED;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.status = ApiStatus.FAILED;
      });
  },
});

export const {
  setAuthenticated, authenticate, authenticateFromLegacy,
} = authSlice.actions;

export default authSlice.reducer;
