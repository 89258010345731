import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { LpiService } from 'services/lpi/lpi';
import AcceptConsentRequest from 'services/lpi/types/Consent/AcceptConsentRequest';
import ConsentRequest from 'services/lpi/types/Consent/ConsentRequest';
import { ApiStatus } from 'types/enums/ApiStatus';
import { ConsentType } from 'types/enums/ConsentType';

const PRIVACY_POLICY_VERSION = Number(window.env.PUBLIC_PRIVACY_POLICY_VERSION);
const PIPL_VERSION = Number(window.env.PUBLIC_PIPL_VERSION);

export type ConsentsState = {
  consentsFetched: boolean,
  privacyPolicyAccepted: boolean,
  piplAccepted: boolean,
  status: ApiStatus,
  errorCode?: number,
};

interface ConsentsAccepted {
  privacyPolicy: boolean,
  pipl: boolean
}

const initialState: ConsentsState = {
  consentsFetched: false,
  privacyPolicyAccepted: false,
  piplAccepted: false,
  status: ApiStatus.IDLE,
};

const getLatestConsent = (consents: Consent[], consentType: ConsentType) => {
  const filteredConsents = consents.filter((consent) => consent.policyType === consentType);

  if (filteredConsents.length <= 0) {
    return null;
  }

  const latestConsent = filteredConsents.reduce((prev, current) => (prev.version > current.version ? prev : current));
  return latestConsent?.version;
};

export const fetchConsents = createAsyncThunk(
  'consents/fetchConsents',
  async (userId: number, { rejectWithValue }) => {
    try {
      const consents = await LpiService.Consents.GetConsents(userId);

      const latestPrivacy = getLatestConsent(consents, ConsentType.PRIVACY_POLICY);
      const privacyPolicyAccepted = (latestPrivacy || 0) >= PRIVACY_POLICY_VERSION;

      const latestPipl = getLatestConsent(consents, ConsentType.PIPL);
      const piplAccepted = (latestPipl || 0) >= PIPL_VERSION;

      return { privacyPolicy: privacyPolicyAccepted, pipl: piplAccepted } as ConsentsAccepted;
    } catch (error: unknown) {
      return rejectWithValue((error as AxiosError).response?.status);
    }
  },
);

export const acceptConsent = createAsyncThunk(
  'consents/acceptConsent',
  async ({ userId, policyType }: AcceptConsentRequest, { rejectWithValue }) => {
    try {
      const request: ConsentRequest = {
        userId,
        policyType,
        version: policyType === ConsentType.PRIVACY_POLICY ? PRIVACY_POLICY_VERSION : PIPL_VERSION,
        status: 'accepted',
      };

      await LpiService.Consents.CreateConsent(request);
      return policyType;
    } catch (error: unknown) {
      return rejectWithValue((error as AxiosError).response?.status);
    }
  },
);

const consentsSlice = createSlice({
  name: 'consents',
  initialState,
  reducers: {
    setPrivacyAccepted: (state) => {
      state.privacyPolicyAccepted = true;
    },
    setPiplAccepted: (state) => {
      state.piplAccepted = true;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch consents
      .addCase(fetchConsents.pending, (state) => {
        state.status = ApiStatus.LOADING;
        state.errorCode = undefined;
      })
      .addCase(fetchConsents.fulfilled, (
        state,
        { payload }: PayloadAction<ConsentsAccepted>,
      ) => {
        state.status = ApiStatus.SUCCEEDED;
        state.consentsFetched = true;
        state.privacyPolicyAccepted = payload.privacyPolicy;
        state.piplAccepted = payload.pipl;
      })
      .addCase(fetchConsents.rejected, (state, { payload }: PayloadAction<unknown>) => {
        state.status = ApiStatus.FAILED;
        state.consentsFetched = false;
        state.errorCode = Number(payload);
      })
      // accept consent
      .addCase(acceptConsent.pending, (state) => {
        state.status = ApiStatus.LOADING;
        state.errorCode = undefined;
      })
      .addCase(acceptConsent.fulfilled, (
        state,
        { payload }: PayloadAction<ConsentType>,
      ) => {
        state.status = ApiStatus.SUCCEEDED;

        if (payload === ConsentType.PIPL) state.piplAccepted = true;
        if (payload === ConsentType.PRIVACY_POLICY) state.privacyPolicyAccepted = true;
      })
      .addCase(acceptConsent.rejected, (state, { payload }: PayloadAction<unknown>) => {
        state.status = ApiStatus.FAILED;
        state.errorCode = Number(payload);
      });
  },
});

export const { setPrivacyAccepted, setPiplAccepted } = consentsSlice.actions;

export default consentsSlice.reducer;
