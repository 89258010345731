import { createMockAdminAssessments } from '__mocks__/adminAssessments';
import { HttpResponse, http } from 'msw';
import generateAdminMocks from 'services/admin/mock';

type FieldNameMappings = {
  [key: string]: string;
};


export const createHandlers = (baseUrl: string | undefined) => {
  const LPI_API_BASE_URL = `${baseUrl}/lpi/api/v4/lpi-assessments`;

  const adminAssessmentList = createMockAdminAssessments({ length: 20 });

  return [
    // Admin feature
    http.get(
      `${LPI_API_BASE_URL}/leaders`,
      async ({ request }) => {
        const searchParam = new URL(request.url).search.replace(/\+/g, '%2B').replace(/-/g, '%2D');
        const params = new URLSearchParams(searchParam);
        const orderBy = params.get('orderBy')?.replace(/%2B/g, '+').replace(/%2D/g, '-');

        const sortedLeaders: Leader[] = [];
        adminAssessmentList.forEach((assessment) => {
          sortedLeaders.push({
            userId: assessment.userId,
            firstName: assessment.firstName,
            lastName: assessment.lastName,
            email: assessment.email,
          });
        });

        const fieldNameMappings: FieldNameMappings = {
          firstname: 'firstName',
          lastname: 'lastName',
          email: 'email',
        };

        // Sorting
        if (orderBy) {
          const fields = orderBy.split(',');

          /*
            This will return an array of object with the following format:
            { fieldName: 'The fieldname itself, direction: 1 ou -1 }
          */
          const orderByFields = fields.map((field) => {
            const direction = field.startsWith('-') ? -1 : 1;
            const fieldNameLower = field.replace(/^[-+]/, '').toLowerCase(); // Remove + or -
            const fieldName = fieldNameMappings[fieldNameLower] || fieldNameLower;

            return { fieldName, direction };
          });

          sortedLeaders.sort((a, b) => {
            for (const { fieldName, direction } of orderByFields) {
              let fieldA = (a as any)[fieldName];
              let fieldB = (b as any)[fieldName];

              if (typeof fieldA === 'string' && typeof fieldB === 'string') {
                fieldA = fieldA.toLowerCase();
                fieldB = fieldB.toLowerCase();
                if (fieldA < fieldB) return -1 * direction;
                if (fieldA > fieldB) return 1 * direction;
              }
            }

            return 0;
          });
        }

        return  HttpResponse.json(
          {
            status: 200,
            items: sortedLeaders,
            count: sortedLeaders.length,
          },
        );
      },
    ),
    ...generateAdminMocks(window.env.PUBLIC_API_BASE_URL),
  ];
};
