import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import styles from './Header.module.scss';

const Header = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex" data-testid="HeaderTemplate-test-id">
      <Box
        className={styles['Header-left']}
        position="relative"
      >
        <img
          className={styles['Header-image']}
          src="/assets/images/header-left.jpg"
          alt={t('layout.header.bannerAlt')}
        />
      </Box>

      <Box
        className={styles['Header-right']}
        my={2.5}
      >
        <img
          className={`${styles['Header-image']} ${styles['Header-imageRight']}`}
          src="/assets/images/TLC-Logo.svg"
          alt={t('layout.header.logoAlt')}
        />
      </Box>
    </Box>
  );
};

export default Header;
