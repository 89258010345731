/* eslint-disable max-len */
import * as falso from '@ngneat/falso';
import { AdminAssessment } from 'types/AdminAssessment';

import { AssessmentStatus } from '../types/AssessmentStatus';

falso.seed('adminassessment-mock');
const idFactory = falso.incrementalNumber();

const createTags = ({ long = false }: { long: boolean }): string[] => {
  if (long) {
    const longTag = `Lorem ipsum dolor sit amet consectetur. Blandit nunc nisi integer placerat dignissim facilisi quis. 
      Sagittis interdum sem orci libero auctor augue. Tortor malesuada congue lacinia etiam amet integer ut magna. 
      Urna est arcu scelerisque netus viverra libero.`;
    return [longTag, longTag];
  }

  return falso.toCollection(
    () => falso.randCompanyName({ length: 10 }),
    { length: falso.randNumber({ min: 0, max: 3 }) },
  ).flat();
};

const createMockAdminAssessmentData = (
  { longTags = false, leader }: { longTags?: boolean; leader?: Leader } = {},
): AdminAssessment => ({
  entitlementId: idFactory().toString(),
  product: falso.rand(['lpi-360', 'lpi-360-plus', 'lpi-self', 'lpi-self-empowered', 'pre-work', 'lpi-student-360', 'lpi-student-self']),
  firstName: leader?.firstName || falso.randFirstName(),
  lastName: leader?.lastName || falso.randLastName(),
  email: leader?.email || falso.randEmail(),
  userId: leader?.userId || idFactory().toString(),
  createdAt: '2023-03-23',
  completedAt: falso.randBoolean() ? '2023-03-23' : undefined,
  status: falso.rand([
    AssessmentStatus.ACCEPTED,
    AssessmentStatus.COMPLETED,
    AssessmentStatus.INVITATION_SENT,
    AssessmentStatus.IN_PROGRESS,
  ]),
  tags: createTags({ long: longTags }),
  surveyComplete: falso.randBoolean() ? '2023-03-23' : undefined,
  reassessmentDate: falso.randBoolean() ? '2023-03-23' : undefined,
  managerComplete: falso.randBoolean(),
  observers: falso.randBoolean() ? falso.randFullName() : undefined,
});

export const createMockAdminAssessment = (
  { longTags = false, leader }: { longTags?: boolean; leader?: Leader } = {},
): AdminAssessment => createMockAdminAssessmentData({ longTags, leader });

export const createMockAdminAssessments = (
  { length = falso.randNumber({ min: 0, max: 100 }), longTags = false, leader }: { length?: number; longTags?: boolean; leader?: Leader } = {},
): AdminAssessment[] => (
  Array.from({ length }, () => createMockAdminAssessmentData({ longTags, leader }))
);
