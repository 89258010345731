/* eslint-disable @typescript-eslint/no-unused-vars */
import { createMockAdminAssessments } from '__mocks__/adminAssessments';
import { createMockLeaders } from '__mocks__/leader';
import { http, HttpResponse } from 'msw';
import { AdminAssessment } from 'types/AdminAssessment';

const getRandomLeaders = (leaders: Leader[], n: number): Leader[] => {
  const shuffled = leaders.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, n);
};

export default function generateMocks(baseUrl?: string) {
  const lpiApiBaseUrl = `${baseUrl}/lpi/api/v4`;

  const leaderList = createMockLeaders(40);
  const assessmentList = Array.from({ length: 40 }, () => {
    const randomLeader = getRandomLeaders(leaderList, 1)[0];
    return createMockAdminAssessments({ length: 1, leader: randomLeader })[0];
  });

  return [
    http.post<never, never, { items: AdminAssessment[], count: number }>(
      `${lpiApiBaseUrl}/lpi-assessments/search`,
      async ({ request }) => {
        const url = new URL(request.url);

        // Query params
        const orderBy = url.searchParams.get('orderBy')?.replace(/%2B/g, '+').replace(/%2D/g, '-');
        const offset = parseInt(url.searchParams.get('offset') || '0', 10);
        const size = parseInt(url.searchParams.get('size') || '20', 10);

        console.log('orderBy', orderBy);

        // Request body
        const {
          statuses = [] as string[],
          userIds = [] as string[],
          invitedLeaders = [] as string[],
          products = [] as string[],
        } = await request.json();

        let filteredAssessments = [...assessmentList];

        if (statuses.length > 0) {
          filteredAssessments = filteredAssessments.filter((assessment) =>
            statuses.includes(assessment.status),
          );
        }

        if (userIds.length > 0) {
          filteredAssessments = filteredAssessments.filter((assessment) =>
            userIds.includes(assessment.userId),
          );
        }

        if (invitedLeaders.length > 0) {
          filteredAssessments = filteredAssessments.filter((assessment) =>
            invitedLeaders.includes(assessment.userId),
          );
        }

        if (products.length > 0) {
          filteredAssessments = filteredAssessments.filter((assessment) =>
            products.includes(assessment.product),
          );
        }

        const fieldNameMappings: Record<string, keyof AdminAssessment | keyof Leader> = {
          LeaderFirstName: 'firstName',
          LeaderLastName: 'lastName',
          CompletedBy: 'completedAt',
        };

        if (orderBy) {
          const fields = orderBy.split(',');

          const orderByFields = fields.map((field) => {
            const direction = field.startsWith('-') ? -1 : 1;
            const fieldName = field.replace(/^[-+]/, '');

            return { fieldName, direction };
          });

          filteredAssessments.sort((a, b) => {
            for (const { fieldName, direction } of orderByFields) {
              const mappedField = fieldNameMappings[fieldName as keyof typeof fieldNameMappings];

              if (!mappedField) continue;

              let fieldA = (a as any)[mappedField];
              let fieldB = (b as any)[mappedField];

              if (typeof fieldA === 'string' && typeof fieldB === 'string') {
                fieldA = fieldA.toLowerCase();
                fieldB = fieldB.toLowerCase();
                if (fieldA < fieldB) return -1 * direction;
                if (fieldA > fieldB) return 1 * direction;
              }

              if (mappedField === 'completedAt' && fieldA && fieldB) {
                const dateA = new Date(fieldA).getTime();
                const dateB = new Date(fieldB).getTime();
                return (dateA - dateB) * direction;
              }
            }

            return 0;
          });
        }

        // Pagination
        const paginatedItems = filteredAssessments.slice(offset, offset + size);

        return HttpResponse.json({
          items: paginatedItems,
          count: filteredAssessments.length,
        });
      },
    ),
    http.get<never, never, { items: Leader[] }>(
      `${lpiApiBaseUrl}/lpi-leader/search`,
      async ({ request }) => {
        const url = new URL(request.url);

        const keyword = url.searchParams.get('keyword');
        const email = url.searchParams.get('email');

        let data = [...leaderList];

        if (email) {
          data = data.filter((leader) => leader.email.toLowerCase() === email.toLowerCase());
        }

        if (keyword) {
          const lowerCaseKeyword = keyword.toLowerCase();

          data = data.filter((leader) =>
            leader.firstName.toLowerCase().includes(lowerCaseKeyword) ||
            leader.lastName.toLowerCase().includes(lowerCaseKeyword),
          );
        }

        return HttpResponse.json({ items: data });
      },
    ),
    http.post(
      `${baseUrl}/api/v4/batch-reports`,
      async () => {
        return  HttpResponse.json(
          {
            url: '/',
          },
        );
      },
    ),
  ];
}
