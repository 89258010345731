const pendoClient = {
  initialize: () => {
    if (Object.hasOwn(window, 'pendo')) {
      (window as any).pendo.initialize({
        visitor: {
          id: 'VISITOR-UNIQUE-ID',
        },
      });
    }
  },
};

export default pendoClient;
